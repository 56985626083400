<template>
    <b-form @submit.prevent="enviarForm">
              <b-form-row>
                
                <b-col cols="12" md="4">
                  <label  for="licencia">Licencia:</label>
                  <b-form-input
                    id="licencia"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Licencia"
                    :disabled="form.acta != null && form.acta != ''"
                    v-model="form.licencia"
                  ></b-form-input>
                </b-col>
                
                <b-col cols="12" md="4">
                  <label  for="acta">Acta No.</label>
                  <b-form-input
                    id="acta"
                    class="mb-2 mr-sm-2 mb-sm-0"
                    placeholder="Acta No."
                    :disabled="form.licencia != null && form.licencia != ''"
                    v-model="form.acta"
                  ></b-form-input>
                </b-col>

                <b-col cols="12" md="4"  align-self="end">
                    <b-button class="vertical-align" variant="secondary" type="submit" block>
                        <b-icon icon="search"></b-icon>
                        Consultar
                    </b-button>
                </b-col>

              </b-form-row>
          </b-form>
</template>

<script>
import { mapActions } from 'vuex'
import {encontrarError} from '../../contantesErrores'

export default {
    name: 'FormularioConsultaGestionVisita',
    data(){
      return{
        form: {
          licencia: null,
          acta: null
        }
      }
    },
    methods:{
      ...mapActions('gestionarVisitas', ['cargarVisitasProgramadas', 'buscarVisitasNumActa', 'buscarVisitasLicencia']),
      
      async enviarForm(){
        if(this.form.acta != null && this.form.acta != ''){
          try{
            await this.buscarVisitasNumActa(this.form.acta);
          }catch(e){
            encontrarError(this, e.data)
          }
        }else if(this.form.licencia != null && this.form.licencia != ''){
          try{
            await this.buscarVisitasLicencia(this.form.licencia);
          }catch(e){
            encontrarError(this, e.data)
          }
        }else {
          try{
            await this.cargarVisitasProgramadas();
          }catch(e){
            encontrarError(this, e.data)
          }
        }
      }
    }
}
</script>

<style scoped>
   
</style>
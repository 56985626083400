<template>
  <div>
    <div class="cajaSuperior">
      <b-container>
        <b-row>
          <b-col cols="12" md="3">
            <h2>criterios de <br /> consulta</h2>
          </b-col>

          <!-- Formulario consulta -->
          <b-col>
            <div class="contenedorForm pl-5">
              <FormularioConsultaGestionVisita />
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>

    <CalendarioVisitas :visitas="visitasCargadas" @visitaSeleccionada="recibirVisitaSeleccionada"/>

    <b-modal id="modalVisitasCargadas" hide-footer size="lg">
      <template #modal-title>
        Detalle de visitas para el día {{fechaACargar}}
      </template>
      <div class="d-block text-center">
          <b-table
              id="visitas" 
              striped 
              responsive 
              small 
              bordered 
              :items="visitasPorDia"
              :fields="camposTabla" 
              :per-page="perPage"
              :current-page="currentPage"
              :tbody-tr-class="rowClass" >
              <template #cell(accion)="row">
                  <b-button size="sm" v-if="row.item.estado == '2'" class="mr-1 text-center" block @click="gestionarVisitaSeleccionada(row.item, true)">
                      Ver detalles
                  </b-button>
                  <b-button size="sm" v-if="row.item.estado != '2'" class="mr-1 text-center" block @click="gestionarVisitaSeleccionada(row.item, false)">
                      Gestionar
                  </b-button>
              </template>
          </b-table>

          <b-row>
              <b-col>
                  <b-pagination 
                      v-model="currentPage" 
                      :total-rows="rowsPaginador" 
                      :per-page="perPage" 
                      aria-controls="visitas">
                  </b-pagination>
              </b-col>
              <b-col class="text-right">
                  <b-button @click="$bvModal.hide('modalVisitasCargadas')">
                    Cerrar
                  </b-button>
              </b-col>
          </b-row>
      </div>
    </b-modal>
    
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import FormularioConsultaGestionVisita from '../components/gestionVisitas/FormularioConsultaGestionVisita.vue'
  import CalendarioVisitas from '../components/gestionVisitas/calendario/CalendarioVisitas.vue'
  import {encontrarError} from '../contantesErrores';
  import { traerNombreEstado } from '../constantes';

  export default {
    name: 'GestionVisita',
    data(){
      return{
        fechaACargar: '',
        camposTabla: [
          'num_acta',
          {
              key: 'estado',
              formatter: (value)=>{
                  let estado= traerNombreEstado(value)
                  return estado.nombre
              }
          },
          {
            key: 'direccion',
            label: 'Dirección'
          },
          'hora_visita',
          {
            key:'accion',
            label: 'Acción'
          }
        ],
        perPage: 10,
        currentPage: 1,
      }
    },
    async mounted(){
      try{
        await this.cargarVisitasProgramadas();
      }catch(e){
        encontrarError(this, e.data)
      }
    },
    components: {
      FormularioConsultaGestionVisita,
      CalendarioVisitas
    },
    computed:{
      ...mapState('gestionarVisitas', ['visitasCargadas', 'visitasPorDia']),
      rowsPaginador() {
          return this.visitasCargadas.length
      }
    },
    methods:{
      ...mapActions('gestionarVisitas', ['activarVerDetalle','cargarVisitasProgramadas', 'cargarVisitasPorDia', 'guardarVisitaEnGestion']),

      rowClass(item, type) {
          if (!item || type !== 'row') return
          return 'rowspace'
      },

      async recibirVisitaSeleccionada(visitaFecha){
        this.fechaACargar = visitaFecha
        try{
          await this.cargarVisitasPorDia(visitaFecha)
          this.$bvModal.show('modalVisitasCargadas')
        }catch(e){
          encontrarError(this, e.data)
        }
      },

      gestionarVisitaSeleccionada(visita, detalle){
        
        this.activarVerDetalle(detalle)
        this.guardarVisitaEnGestion(visita)
        this.$router.push({name: 'formularioGestionVisita'})
      },
    }
  }
</script>

<style scoped>
  
</style>
